import './App.css';
import image from './Images/2.png'
import About1 from './About1';
import Contact from './Contact';
function Home1(){
    return(
        <>
        <div className="divstyle1" >
            <div  style={{width:'50%',float:'left',textAlign:'center',color:'white'}}>
                <h1 style={{fontSize:42,marginTop:150,}}>Welcome To Home </h1>
                <p style={{width: 600,margin:'auto',fontSize:28}}>Welcome to my website, where every click unfolds a world of information, inspiration, and innovation.</p>
                <button className='button1'>Sign In</button>
            </div>
            <div  style={{width:'50%',float:'left',textAlign:'center'}}>
                <img src={image} alt="Description of the " style={{marginTop:42,width:540,height:540,}} />
            </div>
        </div>
        <About1/>
        <Contact/>
        </>
    );
}
export default Home1;
