import './App.css';
import { NavLink } from 'react-router-dom';
import logo from './Images/Untitled-1.png'
function Navbar() {
    return (
        <div className='secHeader'>
            <div style={{ width: '20%', display: 'flex' }}>
                <img src={logo} alt='logo' className='logo' /><h1 style={{ fontSize: 24, color: 'white', fontFamily: 'cursive' }}>Innovate</h1>
            </div>
            <ul>
                <li><NavLink to="/">Home</NavLink></li>
                <li><NavLink to="/About" >About</NavLink></li>
                <li>
                    <div className="dropdown">
                        <button className="dropbtn">Dropdown</button>
                        <ul className="dropdown-content">
                            <li><NavLink to="/User/Anil" >Anil</NavLink></li>
                            <li><NavLink to="/User/Gurnam" >Gurnam</NavLink></li>
                        </ul>
                    </div>
                </li>
                <li><NavLink to="/Contact" >Contact</NavLink></li>
                <li><NavLink to="/Login" >Login</NavLink></li>
            </ul>
        </div>
    );
}
export default Navbar;

///With Link
//  <ul>
//     <li><Link to="/" className='link'>Home</Link></li>
//     <li><Link to="/About" className='link'>About</Link></li>
//     <li><Link to="/Contact" className='link'>Contact</Link></li>
// </ul> 