
function Login(){    
    return(
        <div style={{width:'100%',height:600,textAlign:'center'}}>
            <h1>Login</h1>
            <input type="text"/><br/>
            <input type="text"/><br/>
            <button >Login</button>
        </div>
    );
}
export default Login;


//import { useEffect } from "react";
//import { useNavigate } from "react-router-dom";
// const nevigate = useNavigate();
//     const login1=()=>{
//         localStorage.setItem('login',true);
//         nevigate('/');
//     }    
//     useEffect(() => {
//         let login = localStorage.getItem('login');
//         if (login) {
//             nevigate('/')
//         }
//     })
// <button onClick={login1}>Login</button>