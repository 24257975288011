import { useParams } from "react-router-dom";
function User() {
    const param = useParams();
    const { name } = param;
    return (
        <div className="divstyle">
            <h1 className='center'>{name}</h1>
            <div style={{width:'80%',margin:'auto'}}>
                <h2 style={{color:'#52b0c9'}}>About {name}</h2>
                <p style={{fontSize:20,wordSpacing:10,lineHeight:'25px',textAlign:'justify'}}>
                    {name} is a skilled and dedicated software developer, known for his proficiency in various programming languages and development frameworks.

                    With a keen eye for detail, {name} excels in creating robust and efficient software solutions that meet client requirements.

                    His problem-solving skills and innovative approach make him  a valuable asset in any development team, consistently delivering high-quality code and applications.

                    {name} is well-versed in the latest technology trends, staying up-to-date with industry advancements to ensure the incorporation of cutting-edge practices in his  work.

                    His  excellent communication skills and collaborative nature contribute to effective teamwork and seamless project execution.

                    {name} is passionate about continuous learning, always seeking opportunities to enhance his  skills and explore new areas of development.

                    Known for his  reliability and strong work ethic, {name} consistently meets deadlines and exceeds expectations in the projects he undertake.

                    His  adaptability allows him  to navigate complex challenges, making him  a versatile developer capable of handling diverse tasks.

                    {name}'s commitment to code quality and adherence to best practices make him  a trusted professional in the software development community.

                    Overall, {name} is a dynamic and talented developer, making significant contributions to the field with his  expertise and dedication.</p>
            </div>
        </div>
    );
}
export default User;