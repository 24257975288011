import './App.css';
import image1 from './Images/Web.jpg';
import image2 from './Images/Mobile.jpg';
import image3 from './Images/Marketing.jpg';
import image4 from './Images/24_7.jpg';
function About1(){
    return(
        <div className="divstyle" style={{height:850}}>
            <h1 className='center'>About Us</h1>
            <p style={{width:'80%',margin:'auto',fontSize:20,textAlign:'justify'}}>Paragraph Writing: Take a wild guess as to what will you say to describe a paragraph? From whatever you may know so far, many students describe paragraphs as what consists the story, or a set of sentences that are grouped together to form a paragraph or a set of sentences that cover half a page of your story, and so on.

            Paragraph Writing: Take a wild guess as to what will you say to describe a paragraph? From whatever you may know so far, many students describe paragraphs as what consists the story, or a set of sentences that are grouped together to form a paragraph or a set of sentences that cover half a page of your story, and so on.


            Paragraph Writing: Take a wild guess as to what will you say to describe a paragraph? From whatever you may know so far, many students describe paragraphs as what consists the story, or a set of sentences that are grouped together to form a paragraph or a set of sentences that cover half a page of your story, and so on.

            Although these ideas may look true in many instances, they don’t really define what the idea behind a paragraph is. This is one of those subtle things in English writing that never really gets explained on priority making it one of those commonly used things that are barely understood. Which is why this read is going to be great.</p>
            <h3 style={{textAlign:'center',color:'#52b0c9',fontSize:35}}>Our Expertise</h3>
            <div style={{width:'100%'}}>
                <div className='aboutdiv' style={{marginLeft:85}}>
                    <img src={image1} alt='Website Design & Developmen' className='imgesc'/>
                    <h4>Website Design & Development</h4>
                    <p>Web Designing and Development in impressive way is not just a profession but passion for our Company.Our dedicated team is committed to creating visually stunning layouts, seamless navigation, and interactive interfaces.</p>
                </div>
                <div className='aboutdiv'>
                    <img src={image2} alt='Mobile App Development' className='imgesc'/>
                    <h4>Mobile App Development</h4>
                    <p>Our mobile application development process begins with a thorough understanding of our customers real needs.We have in-depth discussions to uncover our clients' unique goals, ensuring every aspect of the app matches their vision seamlessly.</p>
                </div>
                <div className='aboutdiv'>
                    <img src={image3} alt='Digital Marketing Service' className='imgesc'/>
                    <h4>Digital Marketing Service</h4>
                    <p>We're a dedicated digital marketing company in Patiala, focused on fulfilling our clients' needs. Our approach is goal-oriented, striving for excellence in the dynamic digital landscape.we aim to surpass ambitious goals through creative and data-driven strategies.</p>
                </div>
                <div className='aboutdiv'>
                    <img src={image4} alt='24/7 Support' className='imgesc'/>
                    <h4>24/7 Support</h4>
                    <p>Round the clock unmatched technical support is provided by our support center. We serve our clients with prompt response with flexible service options depending upon the needs.We take pride in our flexibility service options to accommodate diverse requirements.</p>
                </div>
            </div>
        </div>
    );
}
export default About1;
