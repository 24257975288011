import React, { useState } from 'react';
function Contact() {
    const [firstName, SetfirstName] = useState('');
    const [lastName, SetlastName] = useState('');
    const [email, Setemail] = useState('');
    const [message, Setmessage] = useState('');
    const [firstNameerr, SetfirstNameerr] = useState(false);
    const [lastNameerr, SetlastNameerr] = useState(false);
    const [emailerr, Setemailerr] = useState(false);
    const [messageerr, Setmessageerr] = useState(false);
    function getData(e) {
        e.preventDefault();

        if (firstName === '') {
            SetfirstNameerr(true);
            return false;
        }
        else {
            SetfirstNameerr(false);
        }
        if (lastName === '') {
            SetlastNameerr(true);
            return false;
        }
        else {
            SetlastNameerr(false);
        }
        if (email === '') {
            Setemailerr(true);
            return false;
        }
        else {
            Setemailerr(false);
        }
        if (message === '') {
            Setmessageerr(true);
            return false;
        }
        else {
            Setmessageerr(false);
        }


    }
    return (
        <div className="divstyle">
            <h1 className='center'>Contact</h1>
            <form onSubmit={getData}>
                <table className="tablest">
                    <tbody>
                    <tr>
                        <td className="tabletxt">First Name</td>
                        <td className="tabletxt">Last Name</td>
                    </tr>
                    <tr>
                        <td>
                            <input type="text" className="inputst" onChange={(e) => SetfirstName(e.target.value)} /><br />
                            {firstNameerr ? <span className="error">Please enter your first name.</span> : null}
                        </td>
                        <td>
                            <input type="text" className="inputst" onChange={(e) => SetlastName(e.target.value)} /><br />
                            {lastNameerr ? <span className="error">Please enter your Last name.</span> : null}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2} className="tabletxt">Email</td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <input type="text" className="inputst" style={{ width: '95%' }} onChange={(e) => Setemail(e.target.value)} /><br />
                            {emailerr ? <span className="error">Please enter your Email.</span> :null}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2} className="tabletxt">
                            Message
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <textarea style={{ height: 120, borderRadius: 6, width: '98%', border: 'none' }} onChange={(e) => Setmessage(e.target.value)}></textarea><br />
                            {messageerr ? <span className="error">Please enter Message.</span> : null}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2} style={{ textAlignLast: 'center' }}>
                            <button className="button2">Submit</button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </form>
        </div>
    );
}
export default Contact;