import './App.css';
import {BrowserRouter,Route, Routes} from 'react-router-dom';
import Home1 from './Home1';
import About1 from './About1';
import Navbar from './Navbar';
import Contact from './Contact';
import User from './User';
import Page404 from './Page404';
import Footer12 from './Footer12';
import Login from './Login';

function App() {
  return (
    <>
      <BrowserRouter>           
      <Navbar/>
      <Routes>
        <Route path='/'  element={<Home1/>}/>
        <Route path='/About'  element={<About1/>}/>
        <Route path='/Contact'  element={<Contact/>}/>
        <Route path='/User/:name'  element={<User/>}/>
        <Route path='/Login'  element={<Login/>}/>
        <Route path='*'  element={<Page404/>}/>
      </Routes>
      <Footer12/>
      </BrowserRouter>     
    </>
  );
}

export default App;
//  <Link to="/">Home</Link> link
//we dont use Anchor tag for link because it
// <BrowserRouter>
//     <Link to="/">Home</Link>
//     <br/>
//     <Link to="/About">About</Link>
//     <Routes>
//       <Route path='/'  element={<Home1/>}/>
//       <Route path='/About'  element={<About1/>}/>
//     </Routes>
//     </BrowserRouter>  


//with Protected Component routing
//import Protected from './Protected';
//  <BrowserRouter>           
// <Navbar/>
// <Routes>
//   <Route path='/'  element={<Protected Component={Home1} />}/>
//   <Route path='/About'  element={<Protected Component={About1} />}/>
//   <Route path='/Contact'  element={<Protected Component={Contact} />}/>
//   <Route path='/User/:name'  element={<Protected Component={User} />}/>
//   <Route path='/Login'  element={<Login/>}/>
//   <Route path='*'  element={<Page404/>}/>
// </Routes>
// <Footer12/>
// </BrowserRouter>    